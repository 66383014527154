<template>
  <div>
    <!-- showcase -->
    <div class="showcase-xxs">
      <h1 style="color: #009687;">Support</h1>
    </div>

    <div class="wrapper">
      <!-- section prices -->
      <section
        id="section-support"
        class="section jpadding jpadding-20 section-unfixed"
        style="background: #009687;"
      >
        <div class="jcard jcard-dash">
          <h4><i class="fad fa-book"></i></h4>
          <h4>User Guide</h4>
          <small
            >We composed a detailed user manual to guide you through the entire
            Talkii experience.</small
          >
        </div>

        <div class="jcard jcard-dash">
          <h4><i class="fad fa-telescope"></i></h4>
          <h4>Faq</h4>
          <small
            >Look in here first, some of your questions might have already been
            answered.</small
          >
        </div>

        <div class="jcard jcard-dash">
          <h4><i class="fad fa-life-ring"></i></h4>
          <h4>Let us help</h4>
          <small>Contact us directly to find a solution together.</small>
        </div>

        <div class="jcard jcard-dash">
          <h4><i class="fad fa-box-open"></i></h4>
          <h4>Package return</h4>
          <small>Problem with your package devices?</small>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase/app";
// import C from "@/constants";

export default {
  name: "Dashboard",
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
#section-support {
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
  justify-content: center;
  align-items: center;

  min-height: unset;
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 830px) {
  #section-support {
    grid-template-columns: auto;
  }
}
</style>
